import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./home-video.css"

export default function HomeVideoComponent() {
  const imageSliderSettings = {
    fade: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    lazyLoad: true,
    pauseOnHover: false,
    cssEase: "linear",
  }

  const sources = useStaticQuery(graphql`
    query MyQuery {
      desktopImages: allFile(
        filter: { relativeDirectory: { eq: "home/desktopBackImages" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      mobileImages: allFile(
        filter: { relativeDirectory: { eq: "home/mobileBackImages" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let art = []
  for (let i = 0; i < sources.mobileImages.edges.length; i++) {
    art.push([
      sources.desktopImages.edges[i].node.childImageSharp.fluid,
      {
        ...sources.mobileImages.edges[i].node.childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
    ])
  }

  return (
    <div className="home-video-container">
      <div className="video-main-container">
        <Slider {...imageSliderSettings}>
          {art.map((i, index) => (
            <Img
              key={`index${index}`}
              fluid={i}
              className="video-main-slider-image"
            />
          ))}
        </Slider>
      </div>
      <div className="home-video-lower-section">
        <div className="home-video-lower-section-wrapper">
          <div className="home-video-lower-section-text">
            Making Farmers Prosperous
          </div>
        </div>
      </div>
    </div>
  )
}
