import React, { useState } from "react"
import { useWindowSize } from "../size-hook"
import Slider from "react-slick"

import background from "../../images/service.svg"

import services1 from "../../images/services/service1.png"
import services2 from "../../images/services/service2.png"
import services3 from "../../images/services/service3.png"
import services4 from "../../images/services/service4.png"
import services5 from "../../images/services/service5.png"
import services6 from "../../images/services/service6.png"

import nextArrow from "../../images/next.svg"
import prevArrow from "../../images/back.svg"

import "./index.css"

export default function Services() {
  const [state, setState] = useState(1)
  const size = useWindowSize()

  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
  }

  let backgroundImage = ""
  switch (state) {
    case 2:
      backgroundImage = services2
      break
    case 3:
      backgroundImage = services3
      break
    case 4:
      backgroundImage = services4
      break
    case 5:
      backgroundImage = services5
      break
    case 6:
      backgroundImage = services6
      break
    default:
      backgroundImage = services1
      break
  }
  return (
    <>
      {typeof window !== "undefined" && window.innerWidth < 1000 ? (
        <div className="service-slider">
          <Slider {...sliderSettings}>
            <div>
              <div className="service-slider-image">
                <img src={services1} alt="phone" />
              </div>
              <div className="service-slider-header">E-Dairy Mitra</div>
            </div>
            <div>
              <div className="service-slider-image">
                <img src={services2} alt="phone" />
              </div>
              <div className="service-slider-header">MoooFarm Saba</div>
            </div>
            <div>
              <div className="service-slider-image">
                <img src={services3} alt="phone" />
              </div>
              <div className="service-slider-header">
                Digital Livestock Management
              </div>
            </div>
            <div>
              <div className="service-slider-image">
                <img src={services4} alt="phone" />
              </div>
              <div className="service-slider-header">MoooCoins</div>
            </div>
            <div>
              <div className="service-slider-image">
                <img src={services5} alt="phone" />
              </div>
              <div className="service-slider-header">E-commerce</div>
            </div>
            <div>
              <div className="service-slider-image">
                <img src={services6} alt="phone" />
              </div>
              <div className="service-slider-header">Fin-Tech</div>
            </div>
          </Slider>
        </div>
      ) : (
        <></>
      )}
      {typeof window !== "undefined" && window.innerWidth >= 1000 ? (
        <div className="services-main-container">
          <div className="smc-column column-1">
            <div
              className={`smc-column-div ${
                state === 1 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(1)}
            >
              <div className="smc-column-div-heading">E-Dairy Mitra</div>
              <div className="smc-column-div-content">
                Connect with an Expert Veterinarian, schedule a visit at your
                doorstep at affordable and transparent pricing for all
                veterinary services.
              </div>
            </div>
            <div
              className={`smc-column-div ${
                state === 2 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(2)}
            >
              <div className="smc-column-div-heading">MoooFarm Saba</div>
              <div className="smc-column-div-content">
                Knowledge dissemination, Cattle buy and sell, Query resolution,
                information on Loans & Subsidies and Networking with fellow
                farmers on one platform.
              </div>
            </div>
            <div
              className={`smc-column-div ${
                state === 3 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(3)}
            >
              <div className="smc-column-div-heading">
                Digital Livestock Management
              </div>
              <div className="smc-column-div-content">
                Record and maintain cattle lifeline, manage your revenues and
                expenses and get predictive analytics for dairy farm management.
              </div>
            </div>
          </div>
          <div className="smc-column column-2">
            <div className="smc-column-phone-wrapper">
              <div className="smc-phone-image">
                <img src={backgroundImage} alt="phone" />
              </div>
              <div className="smc-back-image">
                <img src={background} alt="background" />
              </div>
            </div>
          </div>
          <div className="smc-column column-3">
            <div
              className={`smc-column-div ${
                state === 4 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(4)}
            >
              <div className="smc-column-div-heading">MoooCoins</div>
              <div className="smc-column-div-content">
                Earn MoooCoins on every data input, redeem to get exciting
                vouchers and discounts and compete with your peers to earn
                rewards.
              </div>
            </div>
            <div
              className={`smc-column-div ${
                state === 5 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(5)}
            >
              <div className="smc-column-div-heading">E-commerce</div>
              <div className="smc-column-div-content">
                Purchase high-quality dairy farming inputs delivered at doorstep
                at an affordable price with easy payment options and superior
                customer support.
              </div>
            </div>
            <div
              className={`smc-column-div ${
                state === 6 ? "smc-active-div" : ""
              }`}
              onClick={() => setState(6)}
            >
              <div className="smc-column-div-heading">Fin-Tech</div>
              <div className="smc-column-div-content">
                Get instant access to credit, affordable cattle insurance
                services and easy premium collection and repayment options from
                the comfort of your home.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
