import React from "react"
import Layout from "../components/layout"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import Slider from "react-slick"
import ServicesComponent from "../components/services"
import HomeVideoComponent from "../components/home-video"

import povertyLogo from "../images/home/sdg1.png"
import genderLogo from "../images/home/sdg2.png"
import partLogo from "../images/home/sdg3.png"
import resLogo from "../images/home/sdg4.png"

import htLogo from "../images/partner/ht.jpg"
import beLogo from "../images/partner/be.png"
import doahLogo from "../images/partner/doah.png"
import hulLogo from "../images/partner/hul.png"
import isLogo from "../images/partner/is.png"
import mLogo from "../images/partner/m.png"
import msLogo from "../images/partner/ms.png"
import nLogo from "../images/partner/n.png"
import rsLogo from "../images/partner/rs.png"
import soLogo from "../images/partner/so.png"
import tiwLogo from "../images/partner/tiw.png"
import wbLogo from "../images/partner/wb.png"
import wefLogo from "../images/partner/wef.png"
import pLogo from "../images/partner/p.png"
import ndLogo from "../images/partner/nd.png"
import ffLogo from "../images/partner/ff.png"

import farmerLogo from "../images/reach1.svg"
import villageLogo from "../images/reach2.svg"
import cattleLogo from "../images/reach3.svg"

import comma from "../images/comma.svg"

import testimonial2 from "../images/test2.png"
import testimonial3 from "../images/test3.png"
import testimonial4 from "../images/test4.png"

import peopleTest1 from "../images/testimonial/test1.png"
import peopleTest2 from "../images/testimonial/test2.png"
import peopleTest3 from "../images/testimonial/test3.png"
import peopleTest4 from "../images/testimonial/test4.png"
import peopleTest5 from "../images/testimonial/test5.png"
import peopleTest6 from "../images/testimonial/test6.png"

import googlePlay from "../images/google.png"
// import appleApp from "../images/apple.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/home.css"
import SEO from "../components/seo"

const HomePage = () => {
  let activeCounter1 = 0
  let activeCounter2 = 0
  let activeCounter3 = 0
  const testimonialsProps = [
    {
      displayPicture: peopleTest1,
      testimonialContent:
        ' "I use the MoooFarm application regularly to maintain my dairy accounts and get timely knowledge about my cattles. I am always on Rank 1 on the MoooCoins leaderboard and redeem it for mineral mixture whenever needed."',
      name: "Paramjeet Kaur",
      designation: "Uksi Saniya",
    },
    {
      displayPicture: peopleTest2,
      testimonialContent:
        '"I started giving Mineral Mixture to cattle and started following clean farm management practices which was earlier missing after the Doctor\'s advice from MoooFarm."',
      name: "Harjit Singh",
      designation: "Alampur",
    },
    {
      displayPicture: peopleTest3,
      testimonialContent:
        '"It is great help by MoooFarm team during the Corona-phase that we are getting free advice from doctors to cure our animals by home remedies -  in such time when there is no doctor available in-person, I am very happy to know about this service in my village."',
      name: "Swati Verma",
      designation: "Ingohata",
    },
    {
      displayPicture: peopleTest4,
      testimonialContent:
        '"Today I am maintaining all my dairy business records in MoooFarm app, which shows dairy farm accountability of profit and loss on a single touch. Also gives alerts related to cattle health and breeding, useful for better farm management."',
      name: "Sagar Bhende",
      designation: "",
    },
    {
      displayPicture: peopleTest6,
      testimonialContent:
        '"It is very great to be a part of MoooFarm because here I get all the information regarding Livestock Management and solution of different problems of animals and the regular visit of VLE is also impressive. Now I really feel that Dairy business has become easier and less expensive."',
      name: "Maya Devi",
      designation: "Chandpurwa",
    },
    {
      displayPicture: peopleTest5,
      testimonialContent:
        '"MoooFarm app and RBP programme is beneficial for dairy farmers, they are guided for proper animal feeding, dairy farm management and data keeping, which helps in increasing the net income."',
      name: "Bhaskar Galhat",
      designation: "Panjara Bothali, Wardha",
    },
  ]
  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slider-dots",
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  }
  return (
    <Layout>
      <SEO title="MoooFarm - Making Farmers Prosperous" />
      <HomeVideoComponent />
      <div className="services-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            Our <span className="heading-bold-text">Services</span> Include
          </div>
        </div>
        <ServicesComponent />
      </div>
      <div className="reach-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            Our Evergrowing <span className="heading-bold-text">Reach</span>
          </div>
        </div>
        <div className="reach-indis-wrapper">
          <div className="reach-indi-container">
            <div className="reach-indi-logo">
              <img src={farmerLogo} alt="farmer-logo" />
            </div>
            <div className="reach-indi-count">
              <div className="reach-indi-count-number">
                <CountUp
                  start={0}
                  end={160620}
                  duration={1.2}
                  separator=""
                  suffix=" +"
                  redraw={true}
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor
                      onChange={e => {
                        if (activeCounter1 <= 1) {
                          activeCounter1++
                          return start()
                        }
                        return {}
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className="reach-indi-count-text">Farmers Touched</div>
            </div>
          </div>
          <div className="reach-indi-container">
            <div className="reach-indi-logo">
              <img src={villageLogo} alt="farmer-logo" />
            </div>
            <div className="reach-indi-count">
              <div className="reach-indi-count-number">
                <CountUp
                  start={0}
                  end={19879}
                  duration={1.2}
                  separator=""
                  suffix=" +"
                  redraw={true}
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor
                      onChange={() => {
                        if (activeCounter2 <= 1) {
                          activeCounter2++
                          return start()
                        }
                        return {}
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className="reach-indi-count-text">Villages Covered</div>
            </div>
          </div>
          <div className="reach-indi-container">
            <div className="reach-indi-logo">
              <img src={cattleLogo} alt="farmer-logo" />
            </div>
            <div className="reach-indi-count">
              <div className="reach-indi-count-number">
                <CountUp
                  start={0}
                  end={67148}
                  duration={1.2}
                  separator=""
                  suffix=" +"
                  redraw={true}
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor
                      onChange={() => {
                        if (activeCounter3 <= 1) {
                          activeCounter3++
                          return start()
                        }
                        return {}
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className="reach-indi-count-text">Cattle on App</div>
            </div>
          </div>
        </div>
      </div>

      <div className="sdg-container">
        <div className="sdg-wrapper">
          <div className="container-heading-wrapper">
            <div className="container-heading-line"></div>
            <div className="container-heading">
              <span className="heading-bold-text">SDG's</span> We Align With
            </div>
          </div>
          <div className="sdg-main-container">
            <div className="sdg-indis-wrapper">
              <div className="sdg-indi-container">
                <div className="sdg-indi-logo">
                  <img src={povertyLogo} alt="poverty-logo" />
                </div>
              </div>
              <div className="sdg-indi-container">
                <div className="sdg-indi-logo">
                  <img src={genderLogo} alt="poverty-logo" />
                </div>
              </div>
              <div className="sdg-indi-container">
                <div className="sdg-indi-logo">
                  <img src={partLogo} alt="poverty-logo" />
                </div>
              </div>
              <div className="sdg-indi-container">
                <div className="sdg-indi-logo">
                  <img src={resLogo} alt="poverty-logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-container">
        <div className="testimonials-wrapper">
          <div className="testimonials-left-section">
            <div className="testimonial-abs-images img-rs-up-section">
              <img src={testimonial4} alt="farmer" />
            </div>
            <div className="comma-wrapper">
              <img src={comma} alt="comma" />
            </div>
            <div className="container-heading-wrapper align-left">
              <div className="container-heading-line"></div>
              <div className="container-heading" style={{ textAlign: "left" }}>
                What our
                <br /> Customers are <br />{" "}
                <span className="heading-bold-text">Saying</span> by
              </div>
            </div>
            <div className="testimonial-abs-images img-ls-section">
              <img src={testimonial2} alt="farmer" />
            </div>
          </div>
          <div className="testimonials-right-section">
            <div className="testimonial-rs-wrapper">
              <div className="testimonial-abs-images img-rs-section">
                <img src={testimonial3} alt="farmer" />
              </div>
              <div className="testimonial-slider-wrapper">
                <Slider {...sliderSettings}>
                  {testimonialsProps.map(testimonial => (
                    <div className="testimonial-indi-container">
                      <div className="testimonial-indi-image">
                        <img
                          src={testimonial.displayPicture}
                          alt="testimonial-user"
                        />
                      </div>
                      <div className="testimonial-indi-content">
                        {testimonial.testimonialContent}
                      </div>
                      <div className="testimonial-indi-designation-name">
                        <div>{testimonial.name}</div>
                        <div>{testimonial.designation}</div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="more-about-mooo-container">
        <div className="more-about-mooo-wrapper">
          <div className="mam-w-content">
            <div className="mam-wc-heading">Learn more about MoooFarm</div>
            <div className="mam-wc-description">
              Download the MoooFarm app and enjoy more useful
              <br />
              features, compatible with every Device.
            </div>
          </div>
          <div className="mam-w-buttons">
            <div>
              <a
                target="_blank"
                href={"https://play.google.com/store/apps/details?id=com.moo"}
              >
                <img src={googlePlay} alt={"google-play-button"} />
              </a>
            </div>
            {/*<div><a href={"#"}><img src={appleApp} alt={"apple-app-button"} /></a></div>*/}
          </div>
        </div>
      </div>

      <div className="trusted-by-container">
        <div className="trusted-mask-1"></div>
        <div className="trusted-mask-2"></div>
        <div className="trusted-wrapper">
          <div className="container-heading-wrapper">
            <div className="container-heading-line"></div>
            <div className="container-heading">
              We are <span className="heading-bold-text">Trusted</span> by
            </div>
          </div>
          <div className="trusted-logo">
            <div className="trusted-logo-wrapper">
              <img src={wbLogo} alt="wb-logo" />
              <img src={doahLogo} alt="doah-logo" />
              <img src={wefLogo} alt="wef-logo" />
              <img src={msLogo} alt="ms-logo" />
            </div>
            <div className="trusted-logo-wrapper-2">
              <img src={hulLogo} alt="hul-logo" />
              <img src={beLogo} alt="be-logo" />
              <img src={ndLogo} alt="nd-logo" />
              <img src={rsLogo} className="tlw-rs-logo" alt="rs-logo" />
            </div>
            <div className="trusted-logo-wrapper-3">
              <img src={mLogo} alt="m-logo" />
              <img src={soLogo} alt="so-logo" />
              <img src={isLogo} alt="is-logo" />
              <img src={ffLogo} alt="ff-logo" />
            </div>
            <div className="trusted-logo-wrapper-4">
              <img src={pLogo} alt="p-logo" />
              <img src={htLogo} alt="ht-logo" />
              <img src={tiwLogo} alt="tiw-logo" />
              <img src={nLogo} alt="n-logo" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
